import { type GeoLocation } from "@src/appV2/Location";

/**
 * Compares locations so that nort-west comes first and south-east last.
 * This is useful for sorting map markers so that there is consistent z-order.
 */
export function compareGeoLocation(a: GeoLocation, b: GeoLocation): number {
  if (a.latitude < b.latitude) {
    return 1;
  }

  if (a.latitude > b.latitude) {
    return -1;
  }

  return a.longitude - b.longitude;
}
