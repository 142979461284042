import type { UseGetQueryOptions } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { convertToGoogleMapsLocation } from "@src/appV2/Location";
import { geoLocationSchema } from "@src/appV2/Location/types";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { FIVE_MINUTES_IN_MILLISECONDS } from "../constants";

const calculateDistanceRequestSchema = z.object({
  origin: geoLocationSchema,
  destination: geoLocationSchema,
});

const distanceSchema = z.object({
  /**
   * The formatted distance in miles.
   * @example
   * "1.2 mi"
   */
  text: z.string(),
  /**
   * The distance in meters.
   */
  value: z.number(),
});

const durationSchema = z.object({
  /**
   * The formatted duration.
   * @example
   * "1 min 30 sec"
   */
  text: z.string(),
  /**
   * The duration in seconds.
   */
  value: z.number(),
});

const calculateDistanceResponseSchema = z.object({
  distance: distanceSchema,
  duration: durationSchema,
});

export type CalculateDistanceRequest = z.infer<typeof calculateDistanceRequestSchema>;
export type CalculateDistanceResponse = z.infer<typeof calculateDistanceResponseSchema>;

export function useGetDrivingDistance(
  props: Partial<CalculateDistanceRequest>,
  options: UseGetQueryOptions<CalculateDistanceResponse> = {}
): UseQueryResult<CalculateDistanceResponse> {
  const { origin, destination } = props;

  return useQuery({
    ...options,
    queryKey: ["getDrivingDistance", origin, destination],
    queryFn: async () => {
      const { origin, destination } = calculateDistanceRequestSchema.parse(props);

      const distanceMatrixService = new google.maps.DistanceMatrixService();
      const response = await distanceMatrixService.getDistanceMatrix({
        origins: [convertToGoogleMapsLocation(origin)],
        destinations: [convertToGoogleMapsLocation(destination)],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      });

      const values = response.rows[0].elements[0];

      return calculateDistanceResponseSchema.parse({
        distance: values.distance,
        duration: values.duration,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_DISTANCE_BETWEEN_TWO_GEOLOCATIONS_FAILURE,
    },
    enabled: calculateDistanceRequestSchema.safeParse(props).success,
    // The distance is not expected to change given the same origin and destination,
    // but traffic conditions may affect the driving duration.
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    refetchOnMount: false,
  });
}
