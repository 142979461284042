import { Box } from "@mui/material";

export function MapMarkerLocation() {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        bgcolor: String(theme.palette.components?.locationMarker?.background),
        borderRadius: theme.borderRadius?.xLarge,
      })}
    >
      <Box
        sx={(theme) => ({
          width: "16px",
          height: "16px",
          bgcolor: String(theme.palette.components?.locationMarker?.foreground),
          border: `1px solid ${String(theme.palette.components?.locationMarker?.border)}`,
          borderRadius: theme.borderRadius?.xLarge,
        })}
      />
    </Box>
  );
}
