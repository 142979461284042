import { Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { parseISO } from "date-fns";

import { Button } from "../components/Button";
import { formatDayOfMonthWithDayName } from "../utils/formatDayOfMonthWithDayName";

interface ShiftDiscoveryListViewDatesEmptyStateProps {
  isoDates?: string[];
  onSelectNextAvailableDate?: () => void;
}

export function ShiftDiscoveryListViewDatesEmptyState(
  props: ShiftDiscoveryListViewDatesEmptyStateProps
) {
  const { isoDates, onSelectNextAvailableDate } = props;

  return (
    <Stack spacing={9} alignItems="center" sx={{ flex: 1 }} justifyContent="center">
      <Title
        textAlign="center"
        variant="h4"
        component="h3"
        // TODO: Add semibold to Title
        sx={(theme) => ({
          marginBottom: 5,
          fontWeight: theme.typography.h4.fontWeight,
          textWrap: "balance",
        })}
      >
        No open shifts on{" "}
        {isoDates?.length === 1
          ? formatDayOfMonthWithDayName(parseISO(isoDates[0]))
          : "selected dates"}
      </Title>

      {isDefined(onSelectNextAvailableDate) && (
        <Button onClick={onSelectNextAvailableDate}>View next open shifts</Button>
      )}
    </Stack>
  );
}
