import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { type ShiftWithType } from "../Shift/types";
import { WorkerShiftCard } from "../Shift/WorkerShift/Card";

interface WorkerShiftsListProps {
  isError: boolean;
  isLoading: boolean;
  shifts: ShiftWithType[];
}

export function WorkerShiftsList({ isError, isLoading, shifts }: WorkerShiftsListProps) {
  const hasShifts = shifts.length > 0;

  if (isError) {
    return <Text>Error loading worker shifts</Text>;
  }

  if (isLoading) {
    return <Text>Loading worker shifts...</Text>;
  }

  if (!hasShifts) {
    return null;
  }

  return (
    <Stack spacing={5}>
      {shifts?.map((shift) => (
        <WorkerShiftCard key={shift._id} shift={shift} />
      ))}
    </Stack>
  );
}
