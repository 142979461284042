import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";

import { SelectableBox } from "../components/SelectableBox";

export function ShiftDiscoveryListViewAllOpenShiftsEmptyState() {
  return (
    <Stack sx={{ flex: 1 }} justifyContent="center" spacing={5}>
      <Title
        textAlign="center"
        variant="h4"
        component="h3"
        // TODO: Add semibold to Title
        sx={(theme) => ({
          fontWeight: theme.typography.h4.fontWeight,
          textWrap: "balance",
        })}
      >
        There are currently no open shifts in your area
      </Title>
      <Text
        semibold
        textAlign="center"
        variant="body2"
        sx={{ paddingBottom: 8, textWrap: "balance" }}
      >
        We&apos;ll send you a notification when new shifts are open
      </Text>

      <Stack spacing={3}>
        <SelectableBox
          withCallToActionIcon
          href={DeprecatedGlobalAppV1Paths.REFERRAL}
          startIconType="user-add"
        >
          Refer a professional
        </SelectableBox>

        <SelectableBox
          withCallToActionIcon
          href={DeprecatedGlobalAppV1Paths.WORKPLACE_REFERRALS}
          startIconType="building"
        >
          Refer a workplace
        </SelectableBox>
      </Stack>
    </Stack>
  );
}
