import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { differenceInDays, formatISO, parseISO, startOfDay } from "date-fns";
import { useEffect, useRef } from "react";

import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { ShiftDiscoveryPageHeader } from "../PageHeader";
import { ShiftDiscoverySearchModeSwitcher } from "../SearchModeSwitcher";
import { useShiftDiscoveryCalendarData } from "../Shift/Calendar/useShiftDiscoveryCalendarData";
import { OpenShiftsList } from "../Shift/Open/List";
import { getShiftDiscoveryDefaultDateRange } from "../utils/getShiftDiscoveryDefaultDateRange";
import { ShiftDiscoveryListViewAllOpenShiftsEmptyState } from "./ListAllEmptyState";
import { ShiftDiscoveryListViewDatesEmptyState } from "./ListDatesEmptyState";
import { ShiftDiscoveryListViewListTitle } from "./ListTitle";
import { useFilteredListViewOpenShiftsData } from "./useFilteredListViewOpenShiftsData";
import { useListViewWorkerShiftsData } from "./useListViewWorkerShiftsData";
import { WorkerShiftsList } from "./WorkerShiftsList";

export function ShiftDiscoveryListViewPage() {
  const { workerShiftsByDate, dateRange } = useShiftDiscoveryCalendarData({
    initialDateRange: getShiftDiscoveryDefaultDateRange(),
  });

  const {
    data: { filteredOpenShifts, unfilteredOpenShifts, workplacesMap, offersMap },
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    isError: openShiftsIsError,
  } = useFilteredListViewOpenShiftsData();

  const {
    filteredWorkerShifts,
    workerShiftsIsLoading: listWorkerShiftsIsLoading,
    workerShiftsIsError,
  } = useListViewWorkerShiftsData();

  const stackRef = useRef<HTMLDivElement>(null);

  const { dates, setDates } = useShiftDiscoveryUserFiltersContext();
  const daysCount = differenceInDays(dateRange.endDate, dateRange.startDate);

  // only show worker shifts if we're selecting particular dates + there are any shifts for the selected dates
  const shouldShowWorkerShifts = dates.some((date) => isDefined(workerShiftsByDate?.[date]));

  useEffect(() => {
    if (isDefined(stackRef.current)) {
      stackRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [dates]);

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.primary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <ShiftDiscoveryPageHeader />

      <Stack ref={stackRef} spacing={7} sx={{ px: 5, py: 6, overflow: "auto", flex: 1 }}>
        {openShiftsIsSuccess && filteredOpenShifts.length > 0 && (
          <ShiftDiscoveryListViewListTitle
            count={filteredOpenShifts.length}
            daysCount={daysCount}
            allShiftsCount={unfilteredOpenShifts.length}
          />
        )}

        {shouldShowWorkerShifts && (
          <WorkerShiftsList
            isError={workerShiftsIsError}
            isLoading={listWorkerShiftsIsLoading}
            shifts={filteredWorkerShifts}
          />
        )}

        <OpenShiftsList
          isError={openShiftsIsError}
          isLoading={openShiftsIsLoading}
          isSuccess={openShiftsIsSuccess}
          shifts={filteredOpenShifts}
          workplacesMap={workplacesMap}
          offersMap={offersMap}
          loadingStateTexts={[
            "Finding you the best-paying shifts nearby",
            "Analyzing shift opportunities just for you",
            "Your perfect shift is just a moment away",
            "Crunching the numbers to maximize your earnings",
            "Digging deep into facility schedules",
            "Searching high and low for the best shifts nearby",
            "We are on it-finding shifts that matter most",
            "Unlocking your next great opportunity",
          ]}
          renderEmptyState={() => {
            if (dates.length > 0) {
              const nextAvailableShift = unfilteredOpenShifts.find(
                (shift) => startOfDay(parseISO(shift.attributes.start)) > parseISO(dates[0])
              );

              return (
                <ShiftDiscoveryListViewDatesEmptyState
                  isoDates={dates}
                  onSelectNextAvailableDate={
                    isDefined(nextAvailableShift)
                      ? () => {
                          const formattedDate = formatISO(
                            parseISO(nextAvailableShift.attributes.start),
                            {
                              representation: "date",
                            }
                          );

                          setDates([formattedDate]);
                        }
                      : undefined
                  }
                />
              );
            }

            return <ShiftDiscoveryListViewAllOpenShiftsEmptyState />;
          }}
        />
      </Stack>

      <ShiftDiscoverySearchModeSwitcher />
    </Stack>
  );
}
