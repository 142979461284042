import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { CbhIcon } from "../components/CbhIcon";
import { MapMarkerBase } from "./MapMarkerBase";

interface MapMarkerExpandedProps {
  payRate?: string;
  rating?: string | number;
  onClick?: () => void;
}

export function MapMarkerExpanded(props: MapMarkerExpandedProps) {
  const { payRate, rating, onClick } = props;

  if (!isDefined(payRate) && !isDefined(rating)) {
    return (
      <MapMarkerBase
        outerColor={(theme) => String(theme.palette.surface?.primary)}
        innerColor={(theme) => String(theme.palette.surface?.invert)}
        onClick={onClick}
      />
    );
  }

  if (!isDefined(payRate) && isDefined(rating)) {
    return (
      <MapMarkerBase
        outerColor={(theme) => String(theme.palette.surface?.primary)}
        innerColor={(theme) => String(theme.palette.surface?.invert)}
        outerSx={{ width: "fit-content", height: "fit-content" }}
        onClick={onClick}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <CbhIcon
            type="star-filled"
            size="xSmall"
            color={(theme) => String(theme.palette.icon?.lightInvert)}
          />
          <Text variant="body2" color={(theme) => String(theme.palette.text?.invertPrimary)}>
            {rating}
          </Text>
        </Stack>
      </MapMarkerBase>
    );
  }

  return (
    <MapMarkerBase
      outerColor={(theme) => String(theme.palette.surface?.primary)}
      innerColor={(theme) => String(theme.palette.surface?.invert)}
      outerSx={{ width: "fit-content", height: "fit-content" }}
      onClick={onClick}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        {isDefined(payRate) && (
          <Stack direction="row" alignItems="baseline">
            <Text semibold color={(theme) => theme.palette.surface?.invert}>
              {payRate}
            </Text>
            <Text semibold variant="caption" color={(theme) => theme.palette.surface?.invert}>
              /h
            </Text>
          </Stack>
        )}
        {isDefined(rating) && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={(theme) => ({
              backgroundColor: theme.palette.surface?.invert,
              borderRadius: theme.borderRadius?.xLarge,
              padding: theme.spacing(1, 3),
            })}
          >
            <CbhIcon type="star-filled" size="xSmall" />
            <Text variant="body2">{rating}</Text>
          </Stack>
        )}
      </Stack>
    </MapMarkerBase>
  );
}
