import { Text } from "@clipboard-health/ui-react";
import { Box, ButtonBase } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { type Location } from "@src/appV2/lib/GoogleMaps";

import { useGetAddressByGeoCode } from "../../lib/GoogleMaps/api/useGetAddressByGeoCode";
import { CopyToClipboardIcon } from "../components/CopyToClipboard/CopyToClipboardIcon";
import { useCopyToClipboardContext } from "../components/CopyToClipboard/useCopyToClipboard";
import { GoogleMapCustomMarkerWrapper } from "./GoogleMapCustomMarkerWrapper";
import { MapMarkerActive } from "./MapMarkerActive";

interface MapWithAddressProps {
  location: Location;
  readonly?: boolean;
}

export function MapWithAddress(props: MapWithAddressProps) {
  const { location, readonly } = props;

  const { copyToClipboard } = useCopyToClipboardContext();

  const { data: address, isSuccess: isAddressSuccess } = useGetAddressByGeoCode(location);

  /**
   * Formats the address to be displayed in two lines
   */
  const formatAddress = () => {
    if (!isAddressSuccess) {
      return "";
    }

    const split = address.formatted?.split(", ") ?? [];

    return `${split.slice(0, 2).join(", ")}\n${split.slice(2).join(", ")}`;
  };

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative", overflow: "hidden" }}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        options={{
          center: location,
          disableDefaultUI: true,
          // Cooperative gesture handling works better if we put this inside a scroll view as
          // scroll events should not interfere with the map gestures
          gestureHandling: readonly ? "none" : "cooperative",
        }}
        onLoad={(map) => {
          // TODO: Investigate and abstract zoom values into an enum like `MapZoomLevel`
          map.setZoom(15);
        }}
      >
        <GoogleMapCustomMarkerWrapper position={location}>
          <MapMarkerActive />
        </GoogleMapCustomMarkerWrapper>
      </GoogleMap>

      {isAddressSuccess && (
        <ButtonBase
          sx={(theme) => ({
            position: "absolute",
            bottom: theme.spacing(5),
            width: `calc(100% - 2 * ${theme.spacing(5)})`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: String(theme.palette.surface?.invert),
            padding: 5,
            borderRadius: theme.borderRadius?.small,
            left: theme.spacing(5),
            right: theme.spacing(5),
            boxShadow: theme.shadows[3],
            whiteSpace: "pre",
            textAlign: "left",
            overflow: "hidden",
          })}
          onClick={async (event) => {
            event.stopPropagation();
            event.preventDefault();

            await copyToClipboard(address.formatted ?? "");
          }}
        >
          <Text variant="body2">{formatAddress()}</Text>

          <CopyToClipboardIcon iconSize="medium" />
        </ButtonBase>
      )}
    </Box>
  );
}
