import { MapMarkerBase } from "./MapMarkerBase";

interface MapMarkerInactiveProps {
  onClick?: () => void;
}

export function MapMarkerInactive({ onClick }: MapMarkerInactiveProps) {
  return (
    <MapMarkerBase
      outerColor={(theme) => String(theme.palette.surface?.invert)}
      innerColor={(theme) => String(theme.palette.surface?.shaded)}
      onClick={onClick}
    />
  );
}
