import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box, ButtonBase, type SxProps, type Theme } from "@mui/material";

export interface MapMarkerBaseProps {
  outerColor: (theme: Theme) => string;
  outerSx?: SxProps<Theme>;
  innerColor?: (theme: Theme) => string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

export function MapMarkerBase(props: MapMarkerBaseProps) {
  const { outerColor, outerSx, innerColor, onClick, children } = props;

  return (
    <ButtonBase
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 0,
        width: "fit-content",
        minHeight: "fit-content",
      }}
      onClick={onClick}
    >
      <Box
        sx={mergeSxProps(
          (theme) => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
            width: "32px",
            height: "32px",
            bgcolor: outerColor(theme),
            borderRadius: theme.borderRadius?.xLarge,
            zIndex: 1,
            boxShadow: theme.shadows[3],
          }),
          outerSx
        )}
      >
        {children ?? (
          <Box
            sx={(theme) => ({
              width: "16px",
              height: "16px",
              bgcolor: innerColor?.(theme),
              borderRadius: theme.borderRadius?.xLarge,
            })}
          />
        )}
      </Box>
      <Box
        sx={(theme) => ({
          width: "22px",
          height: "14.25px",
          marginTop: "-4.5px", // Pull the arrow up slightly to overlap with circle
          bgcolor: outerColor(theme),
          clipPath: "polygon(50% 0%, 100% 100%, 0% 100%)",
          transform: "rotate(-180deg)",
          zIndex: 2,
        })}
      />
    </ButtonBase>
  );
}
