import { type UseGetQueryOptions } from "@src/appV2/api";
import { type DateRange } from "@src/appV2/lib/Calendar";
import { convertToGeoLocation } from "@src/appV2/Location";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { endOfDay, isWithinInterval, parseISO, startOfDay } from "date-fns";
import { useState } from "react";

import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { getIncludedOpenShiftsData } from "../Shift/OpenShifts/getIncludedOpenShiftsData";
import { type GetOpenShiftsResponse, useGetOpenShifts } from "../Shift/OpenShifts/useGetOpenShifts";
import { getShiftDiscoveryDefaultDateRange } from "../utils/getShiftDiscoveryDefaultDateRange";

interface UseListViewOpenShiftsDataProps {
  distance?: number;
  license?: string;
}

export function useListViewOpenShiftsData(
  props: UseListViewOpenShiftsDataProps = {},
  options: UseGetQueryOptions<GetOpenShiftsResponse> = {}
) {
  const { distance = DEFAULT_DISTANCE_IN_MILES_FILTER, license } = props;
  const { dates: dateStrings } = useShiftDiscoveryUserFiltersContext();

  const worker = useDefinedWorker();
  const coordinates = convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  const [dateRange, setDateRange] = useState<DateRange>(getShiftDiscoveryDefaultDateRange());
  const dates = dateStrings.map((dateString) => parseISO(dateString));

  const datesOutsideOfDateRange = dates.filter(
    (date) => !isWithinInterval(date, { start: dateRange.startDate, end: dateRange.endDate })
  );

  const dateFilers = [
    ...datesOutsideOfDateRange.map((date) => ({
      gte: startOfDay(date).toISOString(),
      lte: endOfDay(date).toISOString(),
    })),
    { gte: dateRange.startDate.toISOString(), lte: dateRange.endDate.toISOString() },
  ];

  const { data, ...openShiftsDataResult } = useGetOpenShifts(
    {
      filter: {
        start: dateFilers,
        qualifications: [license ?? ""],
        area: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          radiusInMiles: distance,
        },
      },
    },
    { enabled: Boolean(worker.geoLocation?.coordinates.length === 2 && license), ...options }
  );

  const shifts = data?.data;
  const { offersMap, workplacesMap } = getIncludedOpenShiftsData(data);

  return {
    shifts,
    workplacesMap,
    offersMap,
    ...openShiftsDataResult,
    dateRange,
    setDateRange,
  };
}
