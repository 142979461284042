import { filterOpenShifts } from "../Filters/filterOpenShifts";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { useListViewOpenShiftsData } from "./useListViewOpenShiftsData";

export function useFilteredListViewOpenShiftsData() {
  const { distance, license, ...otherFilters } = useShiftDiscoveryUserFiltersContext();

  const {
    shifts = [],
    workplacesMap = new Map(),
    offersMap = new Map(),
    ...openShiftsDataResult
  } = useListViewOpenShiftsData({
    distance,
    license,
  });

  return {
    data: {
      filteredOpenShifts: filterOpenShifts({ shifts, workplacesMap }, otherFilters),
      unfilteredOpenShifts: shifts,
      offersMap,
      workplacesMap,
    },
    ...openShiftsDataResult,
  };
}
