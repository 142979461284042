import { type DateRange } from "@src/appV2/lib";
import { useAgentShifts } from "@src/appV2/OpenShifts/api/useAgentShifts";
import { formatISO, parseISO } from "date-fns";
import { useState } from "react";

import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import type { TimeSlot } from "../Shift/OpenShifts/types";
import { resolveShiftType } from "../Shift/resolveShiftType";
import type { ShiftWithType } from "../Shift/types";
import { getShiftDiscoveryDefaultDateRange } from "../utils/getShiftDiscoveryDefaultDateRange";

export function useListViewWorkerShiftsData() {
  const { dates } = useShiftDiscoveryUserFiltersContext();

  const [dateRange, setDateRange] = useState<DateRange>(getShiftDiscoveryDefaultDateRange());

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    data: workerShifts,
    isLoading: workerShiftsIsLoading,
    isSuccess: workerShiftsIsSuccess,
    isError: workerShiftsIsError,
    refetch: refetchWorkerShifts,
  } = useAgentShifts({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    tmz: timezone,
    groupByDate: false,
  });

  const rawWorkerShifts: ShiftWithType[] =
    workerShifts?.agentShifts.map((shift) => ({
      ...shift,
      name: shift.name as unknown as TimeSlot,
      type: resolveShiftType({
        urgency: shift.urgency,
        window: shift.window,
        priorityTill: shift.priorityTill,
      }),
    })) ?? [];

  const getFilteredWorkerShifts = () => {
    if (!workerShiftsIsSuccess) {
      return [];
    }

    let result = rawWorkerShifts;

    if (dates.length > 0) {
      result = result.filter((shift) => {
        const shiftDate = formatISO(parseISO(shift.start), { representation: "date" });

        return dates.includes(shiftDate);
      });
    }

    // endpoint returns shifts in random chronological order so we need to sort here
    result.sort((a, b) => {
      return parseISO(a.start).getTime() - parseISO(b.start).getTime();
    });

    return result;
  };

  return {
    /**
     * Raw worker shifts data
     */
    rawWorkerShifts,
    /**
     * Worker shifts data filtered by user selected filters
     */
    filteredWorkerShifts: getFilteredWorkerShifts(),
    workerShiftsIsLoading,
    workerShiftsIsSuccess,
    workerShiftsIsError,
    refetchWorkerShifts,
    dateRange,
    setDateRange,
  };
}
