import { Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { parseISO } from "date-fns";

import { CounterText } from "../components/CounterText";
import { TextButton } from "../components/TextButton";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";
import { formatDayOfMonthWithDayName } from "../utils/formatDayOfMonthWithDayName";

interface ShiftDiscoveryListViewListTitleProps {
  count: number;
  daysCount: number;
  allShiftsCount: number;
}

function getLabel(dates: string[], daysCount: number) {
  if (dates.length === 0) {
    return `All shifts in the next ${daysCount} days`;
  }

  if (dates.length > 1) {
    return "All selected dates";
  }

  // For single date, format it as "Today, Jan 15" or "Mon, Jan 15"
  const date = parseISO(dates[0]);

  return formatDayOfMonthWithDayName(date);
}

export function ShiftDiscoveryListViewListTitle(props: ShiftDiscoveryListViewListTitleProps) {
  const { count, daysCount, allShiftsCount } = props;

  const { dates, appliedFiltersCount, clearFilters } = useShiftDiscoveryUserFiltersContext();

  return (
    <Stack spacing={7} sx={{ px: 3 }}>
      <CounterText count={count}>
        <Title
          bold
          variant="h5"
          component="h2"
          // TODO: add semibold to Title
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightMedium })}
        >
          {getLabel(dates, daysCount)}
        </Title>
      </CounterText>

      {(dates.length > 0 || appliedFiltersCount > 0) && (
        <TextButton
          size="medium"
          onClick={() => {
            clearFilters();
          }}
        >
          Clear all filters to view {allShiftsCount} shifts
        </TextButton>
      )}
    </Stack>
  );
}
